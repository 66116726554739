import React from "react";

// Customizable Area Start
import {
  createTheme,
  styled,
  ThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { Box, Button, Grid, Typography,Container } from "@material-ui/core";
import { orangeBanner } from "./assets";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import SubscriptionCards from "../../../components/src/CommonSubscription.web";
import parse from "html-react-parser";
import { animationVideo } from "../../../../packages/blocks/email-account-login/src/assets";
// Customizable Area End

import LandingPage3Controller, { Props } from "./LandingPage3Controller";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const StyledContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  "& .topTitle": {
    fontSize: "64px",
    fontWeight: "500",
    lineHeight: "70.4px",
    color: "#000",
    fontFamily: "Silika-Medium",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
      lineHeight: "39.6px",
      marginBottom: "16px",
    },
  },
  "& .tagLine": { 
    fontWeight: 300,
    fontSize: "16px",
    lineHeight:"25px",
    color:"#0D0C22",
    marginTop:"50px",
    fontFamily:"Silika-Light",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center",
    textAlign: "center",
    "@media(max-width:960px)": {
      marginTop: "24px !important",
      maxWidth: "384px", 
    },
    "@media(max-width:584px)": {
      marginTop: "24px !important",
      maxWidth: "284px", 
    },
  },
  "& .paymentContainer": {
    backgroundColor: "#F8F8F8",
    justifyContent: "center",
    width: "100%",
  },
  "& .paymentTitle": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "116px",
    paddingLeft: "64px",
    paddingRight: "64px",
    "@media(max-width:790px)": {
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingTop: "71px",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  "& .startProject": {
    backgroundImage: `url(${orangeBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height:"580px",
    "@media(max-width:550px)": {
      padding: "36px 46px",
    },
    "@media(max-width:960px)": {
      height:"280px",
      padding: "36px 46px",
    },
  },
  '& .joinComContent':{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    marginTop:"100px",
    marginBottom:"100px",
    zIndex:10,
    "@media(max-width:960px)": {
      marginTop:"72px !important",
      marginBottom:"50px",
    },
    "@media(max-width:890px)": {
     marginTop:"60px !important",
     marginBottom:"30px",
    },
    "@media(max-width:600px)": {
      marginTop:"40px !important",
    },
  },
  "& .videoBackCover": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover', 
  },
  '& .joinComContainer':{
    backgroundRepeat:"no-repeat",
    position: 'relative',
    backgroundSize:"cover",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    paddingLeft:"69px",
    paddingRight:"69px",
    background:"white",
    "@media(max-width:1000px)": {
      marginTop:"100px !important",
      paddingLeft:"50px",
      paddingRight:"50px",
      marginBottom:"0px !important",
    },
    "@media(max-width:960px)": {
      marginTop:"72px !important",
      paddingLeft:"34px",
      paddingRight:"24px",
      marginBottom:"0px  !important",
    },
    "@media(max-width:433px)": {
      paddingLeft:"24px !important",
      paddingRight:"20px !important",
      marginBottom:"0px",
      marginTop:"0px !important",
    },
  },
  "& .startProjectTitle": {
    fontWeight: 500,
    fontSize: "72px",
    lineHeight: "79.2px",
    fontFamily:"Silika-Medium",
    display:"flex !important",
    justifyContent:"center !important",
    textAlign:"center",
    color:"rgba(13,12,34,1)",
    alignItems:"center !important",
    "@media(max-width:1300px)": {
      fontSize:"60px",
      lineHeight:"50.4px",
    },
    "@media(max-width:917px)": {
      fontSize:"48px",
      lineHeight:"50.4px",
    },
    "@media(max-width:533px)": {
      fontSize:"36px",
      lineHeight:"39.4px",
      maxWidth:"652px",
      paddingRight:"0px",
    },
  },

  "& .startBtnProject": {
    backgroundColor: "#0D0C22",
  },
  "& .getStartedBtn": {
    width: "125px",
    height: "48px",
    borderRadius: "2px",
    color: "white",
    textTransform: "none",
    marginTop: "50px",
    fontSize:"16px",
    boxShadow:"inset 0 0 0 0 #fff !important",
    border:"0.5px solid #0D0D0D",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
    "&:hover":{
      boxShadow:"inset 400px 0 0 0 #fff !important",
      "& .textBtnCreate":{
        color: "#0D0D0D !important" , 
      }  
    },
    "& .textBtnCreate":{
      boxShadow:"inset 0 0 0 0 #fff !important", 
      transition: "color 0.3s ease-in-out",  
    },
  },
  "& .myBrandsContainer": {
    display:"flex",
    justifyContent:"center",
    paddingBottom: "141px",
    backgroundColor: "white",
    paddingLeft:"64px",
    paddingRight:"64px",
    "@media(max-width:686px)": {
      paddingLeft: "25px",
      paddingRight: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "72px",
    },
  },
  "& .cardDiv": {
    width: "25%",
    padding: "10",
    "@media(max-width:1184px)": {
      width: "40%",
    },
    "@media(max-width:950px)": {
      width: "50%",
    },
    "@media(max-width:560px)": {
      width: "90%",
    },
    "@media(max-width:390px)": {
      width: "100%",
    },
  },
}));

const StyledAccordian = styled("div")(({ theme }: { theme: Theme }) => ({
  flexDirection: "column",
  paddingTop: "139px",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "72px",
  },
  display: "flex",
  "@media(max-width:1200px)": {
    paddingRight: "0px",
    paddingTop: "30px",
  },
  "@media(max-width:390px)": {
    paddingTop: "0px",
  },
  "& .accordianMainDiv": {
    "@media(max-width:560px)": {
      marginTop: "0px !important",
    },
  },
  justifyContent: "center",
  width: "100%",
  "& .accodianTitle": {
    fontSize: "48px",
    fontWeight: 500,
    color: "#0D0C22",
    lineHeight: "52.8px",
    justifyContent: "center",
    fontFamily: "Silika-Medium",
    letterSpacing:"-2.2px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
      lineHeight: "39.6px",
    },
  },
  "& .MuiAccordion-root": {
    width: "100%",
    borderRadius: "0px",
    boxShadow: "none",
  },
  "& .MuiIconButton-label": {
    fontSize: "20px",
    color: "#000000",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "44px",
  },
  "& .accordianDetails": {
    backgroundColor: "white",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "60px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  "& .titleBrands": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:1496px)": {
      marginTop: "0px",
    },
    "@media(max-width:1334px)": {
      marginTop: "20px",
    },
  },
  "& .paraBrands": {
    fontWeight: "300",
    fontSize: "16px",
    color: "#0D0C22",
    opacity: "0.8",
    fontFamily: "Silika-Light",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  "& .brandQuoteDiv": {
    letterSpacing: "-1.2px",
    fontFamily: "Silika-Medium",
    lineHeight: "52.2px",
    backgroundColor: "#66A2EB",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "48px",
    paddingLeft: "64px",
    paddingRight: "64px",
    paddingTop: "204px",
    paddingBottom: "204px",
    marginTop: "139px",
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius:"4px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
      fontSize: "24px",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "72px",
      paddingBottom: "72px",
    },
  },
  "& .authorQuote": {
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "24px",
    marginTop: "51px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginTop: "24px",
    },
  },
  "& .backTop": {
    textTransform: "none",
    backgroundColor: "white",
    width: "171px",
    height: "44px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "78px",
    fontWeight: "500",
    fontSize: "16px",
    color: "#010101",
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
  "& .contentOfContainer": {
    justifyContent: "space-between",
    // paddingLeft: "64px",
    // paddingRight: "64px",
    marginTop: "0",
    display: "flex",
    flexWrap: "wrap",
    "@media(max-width:1650px)": {
      paddingLeft: "0px",
      paddingRight: "41px",
      margin: "auto",
    },
  },
  "& .titleDivAcc": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0px",
    marginBottom: "auto",
    fontSize: "48px",
    fontFamily:"Silka",
    "@media(max-width:1604px)": {
      //marginTop:"auto !important",
    },
  },
}));

const StyledSubscriptionContainer = styled("div")(
  ({ theme }: { theme: Theme }) => ({
    padding: "49px 64px 0 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
    "& .subscriptionCardsList": {
      display: "flex",
      justifyContent: "center",
    },
  })
);

const StyleContainer = styled('div')({
  padding:"0px !important",
"& .MuiContainer-root":{
  padding:"0px !important"
},
"@media(max-width:600px)": {
  padding:"0px !important"
 },
})
// Customizable Area End
export default class LandingPage3Web extends LandingPage3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <LandingHeader navigation={this.props.navigation}/>
                <StyleContainer>
                <StyledContainer>
                    <Box className="paymentContainer">
                        <Grid container className="paymentContainer">
                            <Grid item xl={12} className="paymentTitle">
                                <Typography className="topTitle">{this.state.landingPageData[14]?.attributes.title}</Typography>
                                <Typography className="tagLine" style={{maxWidth:"1112px"}}>
                                  {this.state.landingPageData[14]?.attributes.description && parse(this.state.landingPageData[14]?.attributes.description)||"" } 
                                </Typography>
                            </Grid>
                        </Grid>
                        <StyledSubscriptionContainer>
                        <SubscriptionCards 
                            onClickBackToTop={this.handleScrollUp}
                            onClickFeaturesOpen={this.handleFeaturesOpen}
                            subscriptionFeatureCategories={this.state.subscriptionFeatureCategories}
                            subscriptionFeatures={this.state.subscriptionFeatures}
                            subscriptionPlans={this.state.subscriptionPlans}
                            windowWidth={this.state.windowWidth}
                            viewAllFeaturesOpen={this.state.viewAllFeaturesOpen}
                            isHeaderNotNeeded={true}
                        />
                        </StyledSubscriptionContainer>
                        <Box className="myBrandsContainer">
                        <StyledAccordian style={{width:"1152px"}}>                
                            <Grid container className="contentOfContainer">
                                <Grid item xl={6} className="titleDivAcc" style={{maxWidth:"459px"}}>
                                    <Typography className="accodianTitle">
                                      {this.state.landingPageData[15]?.attributes.title}
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} className="titleBrands" style={{maxWidth:"552px"}}>
                                    <Typography className="paraBrands">
                                    {this.state.landingPageData[15]?.attributes.description && parse(this.state.landingPageData[15]?.attributes.description)||""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className="brandQuoteDiv">
                                <Grid item xl={12}>
                                 {this.state.landingPageData[16]?.attributes.description && parse(this.state.landingPageData[16]?.attributes.description)||""}
                                    <Box className="authorQuote"> {this.state.landingPageData[16]?.attributes.title}</Box>
                                </Grid>
                            </Grid>
                        </StyledAccordian>
                        </Box>
                  <Grid container className="joinComContainer" style={{ background: "#0D0C22" }} >
                    <video
                      autoPlay
                      loop
                      muted
                      className="videoBackCover"
                    >
                      <source src={animationVideo} type="video/mp4" />
                    </video>
                    <Grid item xl={8} lg={8} md={10} sm={12} className="joinComContent">
                      <Typography className="startProjectTitle">{this.state.landingPageData[17]?.attributes.title}</Typography>
                      <Typography className="tagLine" style={{ maxWidth: "582px" }}>
                        {this.state.landingPageData[17]?.attributes.description && parse(this.state.landingPageData[17]?.attributes.description) || ""}
                      </Typography>
                      <Button className="getStartedBtn startBtnProject"><Typography className="textBtnCreate">Get Started</Typography></Button>
                    </Grid>
                  </Grid>
                </Box>
                </StyledContainer>
                </StyleContainer>
                <LandingFooter navigation={this.props.navigation}/>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
// Customizable Area End
